import {
  HINT_VIEW_CHECK_REQUEST,
  HINT_VIEW_CHECK_SUCCESS,
  HINT_VIEW_CHECK_ERROR
} from '../Constants/ActionTypes'

import uriRequests from '../Configs/uriRequests'
import dashboardApiRequest from 'src/Configs/dashboardApiRequest'

// setApi faz o bloqueio para setar o reducer sem a requisição na API
export const setHintViewCheck = (hints, setApi = false) => {
  const success = hints => ({ type: HINT_VIEW_CHECK_SUCCESS, hints })
  const error = error => ({ type: HINT_VIEW_CHECK_ERROR, error })

  return async (dispatch, getState) => {
    const {
      user: {
        id: userId
      }
    } = getState()

    const uri = uriRequests.hintViewCheck
      .replace('{userId}', userId)

    dispatch(success(hints))

    if (setApi) {
      try {
        await dashboardApiRequest.post(uri, {
          hints
        })
      } catch (e) {
        dispatch(error(e))
      }
    }
  }
}

export const getHintViewCheck = () => {
  const request = () => ({ type: HINT_VIEW_CHECK_REQUEST })
  const success = (hints) => ({ type: HINT_VIEW_CHECK_SUCCESS, hints })
  const error = (e) => ({ type: HINT_VIEW_CHECK_ERROR, e })

  return async (dispatch, getState) => {
    dispatch(request())
    const {
      user: {
        id: userId
      },
      onboarding: {
        viewedHints
      }
    } = getState()

    /* Evitar chamada da API caso já tenha o cookie setado,
    essa lógica vai mudar quando houverem mais hints no futuro */
    if (viewedHints && viewedHints.length > 0) return

    const uri = uriRequests.hintViewCheck
      .replace('{userId}', userId)

    try {
      const response = await dashboardApiRequest.get(uri)
      return dispatch(success(response.data.hints))
    } catch (e) {
      if (e.response && e.response.status === 404) {
        return dispatch(success([]))
      }

      return dispatch(error(e))
    }
  }
}
