import {
  GET_PLANS_ERROR,
  GET_PLANS_REQUEST,
  GET_PLANS_SUCCESS,
  GET_PLAN_DETAILS_ERROR,
  GET_PLAN_DETAILS_REQUEST,
  GET_PLAN_DETAILS_SUCCESS,
  GET_SELLERS_BY_PLAN_ERROR,
  GET_SELLERS_BY_PLAN_INFINTY_ERROR,
  GET_SELLERS_BY_PLAN_INFINTY_REQUEST,
  GET_SELLERS_BY_PLAN_INFINTY_SUCCESS,
  GET_SELLERS_BY_PLAN_REQUEST,
  GET_SELLERS_BY_PLAN_SUCCESS,
  GET_SELLER_ACTIVE_PLAN_ERROR,
  GET_SELLER_ACTIVE_PLAN_REQUEST,
  GET_SELLER_ACTIVE_PLAN_SUCCESS,
  RESET_PLANS,
  RESET_PLAN_DETAILS,
  RESET_SELLERS_PLAN,
  SET_SELLER_PLAN_ERROR,
  SET_SELLER_PLAN_REQUEST,
  SET_SELLER_PLAN_RESET,
  SET_SELLER_PLAN_SUCCESS
} from '../Constants/ActionTypes'

import createToast from '../Utils/createToast'
import { flattenDeep } from 'lodash'
import plansErrors from '../Errors/plansErrors'
import requestApi from '../Configs/request'
import takeError from '../Errors/takeError'
import uriRequests from '../Configs/uriRequests'
import dashBFFRequestApi from '../Configs/dashBFFRequest'

export const getPlanDetails = (marketplaceId, planId, listPlans = false) => {
  const request = () => ({ type: listPlans ? GET_PLANS_REQUEST : GET_PLAN_DETAILS_REQUEST })
  const success = (data, totalPages) => ({ type: listPlans ? GET_PLANS_SUCCESS : GET_PLAN_DETAILS_SUCCESS, data, totalPages })
  const failure = error => ({ type: listPlans ? GET_PLANS_ERROR : GET_PLAN_DETAILS_ERROR, error })

  const requestPlanDetails = requestApi.get(
    uriRequests.planDetails.replace('{planId}', planId),
    {
      params: { format: 'table' }
    }
  )

  return (dispatch) => {
    dispatch(request())

    if (listPlans) {
      requestPlanDetails
        .then(response => {
          const plans = [response.data]
          const numberOfPages = 1
          dispatch(success(plans, numberOfPages))
        })
        .catch(error => {
          const e = takeError(error, plansErrors)
          if (e.status === 404) {
            dispatch(success([], 1))
          } else {
            dispatch(failure(e))
          }
        })
    } else {
      requestPlanDetails
        .then(response => dispatch(success(response.data)))
        .catch(error => {
          const e = takeError(error, plansErrors)
          createToast('error', e.message, e.status)
          dispatch(failure(e))
        })
    }
  }
}

export const getSellersByPlan = (planId, params, infinity) => {
  const typeRequest = infinity ? GET_SELLERS_BY_PLAN_INFINTY_REQUEST : GET_SELLERS_BY_PLAN_REQUEST
  const typeSuccess = infinity ? GET_SELLERS_BY_PLAN_INFINTY_SUCCESS : GET_SELLERS_BY_PLAN_SUCCESS
  const typeError = infinity ? GET_SELLERS_BY_PLAN_INFINTY_ERROR : GET_SELLERS_BY_PLAN_ERROR

  const request = () => ({ type: typeRequest })
  const success = (data, totalItems, totalPages) => ({ type: typeSuccess, data, totalItems, totalPages })
  const failure = error => ({ type: typeError, error })

  const requestSellersByPlan = requestApi.get(uriRequests.getSellersByPlan
    .replace('{planId}', planId),
  {
    params: {
      limit: 25,
      status: 'active',
      ...params
    }
  })

  return (dispatch) => {
    dispatch(request())

    requestSellersByPlan
      .then(({ data: { items, total, total_pages: totalPages } }) => {
        const sellers = flattenDeep(items)
        dispatch(success(sellers, total, totalPages))
      })
      .catch(error => {
        const e = takeError(error, plansErrors)
        createToast('error', e.message, e.status)
        dispatch(failure(e))
      })
  }
}

export const setSellersToPlan = (planId, sellerId) => {
  const request = () => ({ type: SET_SELLER_PLAN_REQUEST })
  const success = data => ({ type: SET_SELLER_PLAN_SUCCESS, data })
  const failure = error => ({ type: SET_SELLER_PLAN_ERROR, error })

  const params = { idCustomer: sellerId, quantity: 1 }

  const urlSet = uriRequests.setSellerToSubscriptions.replace('{planId}', planId)

  return async (dispatch) => {
    dispatch(request())

    try {
      const response = await dashBFFRequestApi.post(urlSet, params)
      dispatch(success(response.data))
      createToast('success', 'Sucesso! O plano foi <strong>associado</strong> ao estabelecimento.')
    } catch (error) {
      const e = takeError(error, plansErrors)
      if (e.status === 500) {
        createToast('error', 'Não conseguimos <strong>associar</strong> o plano ao estabelecimento. Tente novamente.', sellerId, e.status)
      } else {
        createToast('error', e.message, sellerId, e.status)
      }
      dispatch(failure(e))
    }
  }
}

export const getPlans = (params) => {
  const request = () => ({ type: GET_PLANS_REQUEST })
  const success = (data, totalPages) => ({ type: GET_PLANS_SUCCESS, data, totalPages })
  const failure = error => ({ type: GET_PLANS_ERROR, error })

  const requestPlans = requestApi.get(uriRequests.getPlans, {
    params: {
      sort: 'time-descending',
      ...params
    }
  })

  return (dispatch) => {
    dispatch(request())

    requestPlans
      .then(response => {
        const plans = response.data.items
        const numberOfPages = Math.ceil(response.data.total / response.data.limit) || 1
        dispatch(success(plans, numberOfPages))
      })
      .catch(error => {
        const e = takeError(error, plansErrors)
        createToast('error', e.message, e.status)
        dispatch(failure(e))
      })
  }
}

export const getSellerActivePlan = sellerId => {
  const request = () => ({ type: GET_SELLER_ACTIVE_PLAN_REQUEST })
  const success = activePlan => ({ type: GET_SELLER_ACTIVE_PLAN_SUCCESS, activePlan })
  const failure = error => ({ type: GET_SELLER_ACTIVE_PLAN_ERROR, error })

  return (dispatch) => {
    const urlRequest = uriRequests.sellerSubscriptions
      .replace('{sellerId}', sellerId)

    dispatch(request())

    requestApi.get(urlRequest, {
      params: {
        fee_details: true
      }
    })
      .then(({ data: { items: subscriptions } }) => {
        const activeSubscription = subscriptions.find(subscription => subscription.status === 'active')
        dispatch(success(activeSubscription && activeSubscription.plan))
      })
      .catch(error => {
        dispatch(failure(error))
      })
  }
}

export const resetSetSellerToPlan = () => dispatch => dispatch({ type: SET_SELLER_PLAN_RESET })

export const resetPlanDetails = () => dispatch => dispatch({ type: RESET_PLAN_DETAILS })

export const resetSellersPlan = () => dispatch => dispatch({ type: RESET_SELLERS_PLAN })

export const resetPlans = () => dispatch => dispatch({ type: RESET_PLANS })
